<template>
  <div>
    <SmoothPicker ref='smoothPickerComponent' :data='smoothPickerParams' :change="smoothPickerChange"
      class="smooth-time-select" />
  </div>
</template>

<script>
import { SmoothPicker } from 'vue-smooth-picker';
import 'vue-smooth-picker/dist/css/style.css';
import moment from 'moment-timezone'

export default {
  name: 'SmoothTimeSelect',
  components: { SmoothPicker },
  props: {
    date: {
      type: Object,
      required: true
    },
    parentOpen: {
      type: Boolean,
      required: true
    }
  },
  created() {
    this.initOptionsLists();
    this.init();
  },
  data() {
    return {
      currentDate: moment(this.date),
      minDate: this.initMinDate(),
      selectedTimeState: {
        hours: moment(this.date).hours(),
        minutes: moment(this.date).minutes(),
        timeDay: moment().format('A')
      },
      inx: this.roundTo5(moment(this.date).minute()),
      minInx: this.roundTo5(moment(this.minDate).minute()),
      smoothPickerParams: [
        {
          currentIndex: 0,
          flex: 3,
          list: [],
          textAlign: 'center',
          className: 'item-group box-day',
        },
        {
          currentIndex: 0,
          flex: 4,
          list: [],
          textAlign: 'center',
          className: 'item-group box-day',
        },
        {
          currentIndex: 0,
          flex: 3,
          list: [],
          textAlign: 'center',
          className: 'item-group box-day',
        },
      ],
    }
  },
  methods: {
    roundTo5(a) {
      if (a === 0) {
        return 0
      }
      var b = a % 5;
      b && (a = a - b + 5);
      return a
    },
    initOptionsLists() {
      let sameDay = moment(this.minDate).format("DD MM YYYY") === moment(this.currentDate).format("DD MM YYYY")
      let dayTimeOpt = ['PM']
      if ((this.minDate.format("A") === 'AM' && moment(this.minDate).minutes() < 30) || !sameDay)
        dayTimeOpt = ['AM', 'PM']
      this.smoothPickerParams[2].list = [...dayTimeOpt];
      this.fillOptions()

    },
    fillOptions() {
      let sameDay = moment(this.minDate).format("DD MM YYYY") === moment(this.currentDate).format("DD MM YYYY")
      let sameDayTime = moment(this.minDate).format('A') === this.selectedTimeState.timeDay
      let sameHours = moment(this.minDate).hours() === this.selectedTimeState.hours
      let minutesList = Array.from({ length: 12 }, (_, i) => (i * 5).toString())
      let minHours = parseInt(moment(this.minDate).format('hh'))

      if (sameDayTime && sameDay) {
        if (moment(this.minDate).minutes() > 30 && moment(this.minDate).hours() !== 9 && moment(this.minDate).format('A') !== 'PM') {
          minHours++
        }
        let hours = 12 - minHours

        let offset = minHours
        if (moment(this.minDate).hours() === 12) {
          hours = 12
          offset = 1
        }
        this.smoothPickerParams[0].list = Array.from({ length: hours }, (_, i) => (offset + i).toString())
        if (sameHours) {
          minutesList = Array.from({ length: 12 - this.minInx / 5 }, (_, i) => (((i + this.minInx / 5) * 5).toString()))
        }
      } else {
        this.smoothPickerParams[0].list = Array.from({ length: 12 }, (_, i) => (i + 1).toString())
      }
      this.smoothPickerParams[1].list = minutesList
    },

    init() {
      this.currentDate = moment(this.date)

      this.smoothPickerParams[0].currentIndex = Number(moment(this.date).format('hh')) - this.minDate.format('hh') + 1
      this.smoothPickerParams[1].currentIndex = this.inx / 5 - this.minInx / 5 + 1;
      this.smoothPickerParams[2].currentIndex = moment(this.date).format('A') === 'AM' ? 0 : 1
      this.updateState()
    },
    updateState() {
      Object.assign(
        this.selectedTimeState,
        {
          hours: Number(this.currentDate.format('hh')),
          minutes: Number(this.currentDate.format('mm')),
          timeDay: this.currentDate.format('A')
        })
    },
    initMinDate() {
      let minDate = moment().add(7, 'd')
      return moment(minDate)
    },

    /**
   * @param setGroupData - number of 3 sets (hour, minutes, am)
   * @param getCurrentIndexList - index of value
   */
    smoothPickerChange(setGroupData, getCurrentIndexList) {
      switch (setGroupData) {
        case 0:
          this.selectedTimeState.hours = this.smoothPickerParams[0].list[getCurrentIndexList]
          this.fillOptions()
          break;
        case 1:
          this.selectedTimeState.minutes = this.smoothPickerParams[1].list[getCurrentIndexList]
          break;
        case 2:
          this.selectedTimeState.timeDay = getCurrentIndexList ? 'PM' : 'AM'
          this.fillOptions()
          break;
      }
      this.currentDate = moment(`${moment(this.date).format('MMMM Do YYYY')} ${this.selectedTimeState.hours}: ${this.selectedTimeState.minutes} ${this.selectedTimeState.timeDay}`, ["MMMM Do YYYY hh :mm A"])
      this.$emit('update', this.currentDate);
    }
  },
  watch: {
    date(value) {
      if (typeof value === 'string') {
        this.currentDate = moment(value);
      } else {
        this.currentDate = value
      }
      this.currentDate = moment(value);
      this.init();
    }
  },
}
</script>

<style lang='stylus'>
  .smooth-time-select.smooth-picker {
    background: none !important;

    .smooth-group:first-child .smooth-item {
      text-align right
    }

    .smooth-group:nth-child(3) .smooth-item {
      text-align left
    }

    .smooth-handle-layer {
      .smooth-top,
      .smooth-bottom {
        border: 0
        background: none
      }

      .smooth-middle {
        background: rgba(255,255,255,.15);
        border-radius: 6px;
      }
    }
  }
</style>
