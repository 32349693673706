<template>
  <subview class="newcompetition-date">
    <view-header>
      <custom-header :router-back="goBackToDisciplinePage">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left" />

            {{ $t('pages.newcompetition.date.title') }}
          </div>
        </template>

        <template #last>
          <div class="current-step"> {{ $t('pages.newcompetition.step') }} 1/4</div>
        </template>
      </custom-header>
    </view-header>

    <!-- show modal with select day -->
    <DateDaySelect v-if="showDaySelect" @close="updateDate" :date="date" :minValidDate="minValidDate"
      :maxValidDate="maxValidDate" :disabledDate="disabledDate" />

    <!-- show modal with select time -->
    <DateTimeSelect v-if="showTimeSelect" :isOpen="showTimeSelect" @close="updateDate" :min-valid-date="minValidDate"
      :max-valid-date="maxValidDate" :date="date" />

    <view-content :style="{ marginTop: '8px' }">
      <p v-html="getNotificationBeforeDate" class="admin-text-editor"></p>

      <div class='data-block google-style-input no-'>
        <div @click="showDaySelect = true" class='data-block__time'>
          <span class='data-block__time-text'>{{ currentDay }}</span>
          <Icon :size="24" type="calendar-2" class="data-block__time-icon" />
        </div>

        <div @click="showTimeSelect = true" class='data-block__time'>
          <span class='data-block__time-text'>{{ currentTime }}</span>
          <Icon :size="24" type="clock" class="data-block__time-icon" />
        </div>
      </div>
      <div v-html="getNotificationAfterDate" class="admin-text-editor"></div>

      <!-- <p>To participate in the competition, you must first register yourself by visiting our website and filling out the
        registration form. Make sure to provide accurate information, including your name, age, gender, and contact
        information.</p>
      <p>Events</p>
      <p>We offer multiple events for swimmers of all ages and skill levels. You can choose to participate in one or more
        events, depending on your preference.</p>
      <p>Rules</p>
      <ul>
        <li>Swimmers must wear appropriate swimwear</li>
        <li>Swimmers must swim the entire distance of the event assigned to them</li>
        <li>Swimmers must not use any aids or equipment</li>
        <li>Swimmers must comply with the time limit assigned to them</li>
        <li>Recording and Submitting Your Performance</li>
        <li>Once you have registered and chosen your event(s), you can record yourself swimming using any device with a
          camera. Make sure the video quality is clear and the entire swim is visible.</li>
        <li>After recording, you can submit the video to us via our website. Make sure to submit the video before the
          deadline.</li>
      </ul> -->
    </view-content>

    <view-footer>
      <a-button @click="next" :class="buttonClasses">
        {{ $t('pages.newcompetition.next') }}

        <Icon :size="20" type="arrow-right" />
      </a-button>
    </view-footer>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import moment from 'moment-timezone'
import newcompetition from '@/mixins/newcompetition'
import DateTimeSelect from '@/views/NewCompetition/Date/DateTimeSelect'
import DateDaySelect from '@/views/NewCompetition/Date/DateDaySelect'

export default {
  name: 'Date',
  components: {
    Icon,
    DateTimeSelect,
    DateDaySelect,
  },
  mixins: [
    newcompetition
  ],
  create() {
    this.$store.dispatch('notifications/getNotifications')
  },
  data() {
    const date = moment().add(7, 'd')
    const minValidDate = moment().add(7, 'd')
    const maxValidDate = moment().add(2, 'y')
    const initialDate = date.clone()

    return {
      showTimeSelect: false,
      showDaySelect: false,

      today: new Date(),
      date,
      initialDate,
      minValidDate,
      maxValidDate,
    }
  },
  watch: {
    date(date) {
      this.setDate(date)
    }
  },
  created() {
    const { selectedDate, initialDate } = this

    if (selectedDate && selectedDate > initialDate) {
      this.date = moment(this.selectedDate)
    }
  },
  computed: {
    disabled() {
      return this.initialDate.isSame(this.date)
    },
    buttonClasses() {
      const { disabled } = this

      return { disabled }
    },
    currentTime() {
      return moment(this.date).format('HH:mm');
    },
    currentDay() {
      return moment(this.date).format('DD MMM YYYY');
    },
    getNotificationBeforeDate() {
      const notif = this.$store.getters['notifications/GET_NOTIFICATIONS_TEXTS']
      const el = notif.find(el => el.slug === 'new_competition_before_date_text')
      return el.text
    },
    getNotificationAfterDate() {
      const notif = this.$store.getters['notifications/GET_NOTIFICATIONS_TEXTS']
      const el = notif.find(el => el.slug === 'new_competition_after_date_text')
      if (el) {
        return el.text
      } else {
        return ''
      }

    }
  },
  methods: {
    updateDate(newDate) {
      this.showTimeSelect = false;
      this.showDaySelect = false;
      if (newDate) {
        this.date = newDate;
      }
    },
    getContainer() {
      return document.getElementById('app')
    },
    disabledDate(current) {
      return current < this.initialDate || current > this.maxValidDate
    },
    next() {
      this.$router.push({ name: 'newcompetition.details' })
    },
    goBackToDisciplinePage() {
      this.$router.push({ name: "newcompetition.discepline" });
    },
  }
}
</script>

<style lang="stylus">
  .newcompetition-date { 
    ul {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #7B8B97;
    }
    

    li {
      list-style: disc;
      list-style-position: outside;
      margin-left: 24px;
    }

    .data-block {
      display: flex;
      gap: 16px;
      margin: 24px 0 48px;

      > *:first-child {
        flex 190px 0 0

      }

      &__time {
        background: #0B0D13;
        border-radius: 8px;
        padding 16px
        height 64px
        display flex
        justify-content center
        align-items center
        gap 27px
        flex: 1;
      }

      &__time-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #EEEEEE;
      }

      &__time-icon {
        color: #6293FF;
        width 24px
      }

      @media (max-width: 370px) {
        .data-block__time {
          gap: 20px
        }
      }
    }
  }

  .smooth-picker {
    background: none;

    .smooth-handle-layer .smooth-top {
      background: none;
    }
  }
</style>
