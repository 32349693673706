<template>
  <div @click="$emit('close', null)" class="modal__wrapper">
    <div class="modal__time-row">
      <div @click.stop class="modal__time-value">
        <span class="modal__time-text">
          {{ showDate }}
        </span>
        <Icon :size="24" type="calendar-2" class="modal__time-icon" />
      </div>
    </div>

    <div @click.stop class="modal__block">
      <div class="">
        <div class="modal__block-header">
          <span class="modal__date">{{ currentDate.format('MMMM YYYY') }}</span>
          <a-button @click="previousMonth" class="modal__chevron-button" :disabled='isAllowToShowPrevMonth'>
            <Icon :size="16" type="chevron-left" />
          </a-button>
          <a-button @click="nextMonth" class="modal__chevron-button">
            <Icon :size="16" type="chevron-right" />
          </a-button>
        </div>

        <a-calendar v-model="currentDate" :fullscreen="false" :disabledDate="disabledDate" :mode="'month'" />
      </div>

      <div class='modal__block-footer'>
        <a-button @click="$emit('close', currentDate)" class="modal__block-button">Apply</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import moment from 'moment-timezone';

export default {
  components: {
    Icon
  },
  props: {
    disabledDate: {
      type: Function,
    },
    date: {
      type: Object
    },
    minValidDate: {
      type: Object
    },
    maxValidDate: {
      type: Object
    }
  },
  data() {
    return {
      currentDate: moment(this.date),
      selectedTime: null,
    }
  },
  mounted() {
    this.currentDate = moment(this.date);
  },
  watch: {
    currentDate(newDate) {
      if (newDate.isBefore(this.minValidDate.clone())) {
        this.currentDate = this.minValidDate.clone()
      }
      else if (this.maxValidDate.isBefore(newDate)) {
        this.currentDate = this.maxValidDate.clone()
      }
      else {
        this.currentDate = newDate
      }
    }
  },
  computed: {
    showDate() {
      return moment(this.currentDate).format('DD MMM YYYY')
    },
    isAllowToShowPrevMonth() {
      const prevMonthDate = this.currentDate.clone().subtract(1, 'months');
      return prevMonthDate.isBefore(this.minValidDate.clone().subtract(10, 'days'));
    },
  },
  methods: {
    previousMonth() {
      this.currentDate = this.currentDate.clone().subtract(1, 'months');

    },
    nextMonth() {
      this.currentDate = this.currentDate.clone().add(1, 'months');
    },
  }
}
</script>

<style lang="stylus" scoped>
.modal {
  &__wrapper {
    position: fixed;
    inset: 0;
    background-color: rgba(0,0,0, 0.6);
    z-index: 2;
    padding: 0 16px;
  }

  &__time-value {
    background: #262B3F;
    border-radius: 8px;
    margin: 154px 0 16px 0;
    padding: 16px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 27px;
    width: 190px
  }

  &__time-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #EEEEEE;
  }

  &__time-icon {
    color: #D8D8D8;
    width: 24px;
    min-width: 24px;
  }

  &__block {
    background: #262B3F;
    border-radius: 12px;
    padding: 16px;
  }

  &__block-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  &__block-button {
    height: 40px;
    padding: 8px 24px;
    flex: 0;
    margin: 0;
  }

  &__block-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  &__date {
    margin-right: auto;

    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  &__chevron-button {
    color: #6293FF;
    background-color: transparent;
    padding: 0;
    border: 0;
    flex: 0;
    width: 16px;
    height: 16px;
    margin: 0 0 0 12px;

    &.ant-btn .icon {
      margin: 0;
    }
  }
}

</style>

<style lang="stylus">
  .modal__block {
    .ant-fullcalendar {
      border: 0;
    }

    .ant-fullcalendar-header, .ant-select {
      display: none;
      height: 0;
    }

    .ant-fullcalendar-calendar-body {
      padding: 0;
    }

    .ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
      height: 28px;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      opacity: 0.4;
    }

    .ant-fullcalendar-cell {
      width: 42px;
      height: 42px;
      padding: 3px;

      .ant-fullcalendar-date {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px
      }
    }

    .ant-fullcalendar-value {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #EEEEEE;
      &:hover {
        background-color: rgba(0, 0, 0, 0);
        transform: scale(1.5);
        transition: 0.2s;
        
      }
    }

    .ant-fullcalendar-disabled-cell .ant-fullcalendar-value,
    .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value,
    .ant-fullcalendar-last-month-cell .ant-fullcalendar-value {
      color: #7B8B97;
    }

    .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
      color: rgba(0, 0, 0, 0.6)
    }

    .ant-fullcalendar-selected-day .ant-fullcalendar-date {
      background: #6293FF;
      border-radius: 50%;

      .ant-fullcalendar-value {
        background-color: transparent
      }
    }

    .ant-fullcalendar-today .ant-fullcalendar-value {
      box-shadow: none;
    }
  }
</style>
