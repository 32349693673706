<template>
  <div @click="closeModal()" class="modal__wrapper">
    <div class="modal__time-row">
      <div @click.stop class="modal__time-value">
        <span class="modal__time-text">
          {{ currentTime }}
        </span>
        <Icon :size="24" type="clock" class="modal__time-icon" />
      </div>
    </div>

    <div @click.stop class="modal__block">
      <div v-show="mode === 'presets'" class="modal__presets">
        <a-button v-for="preset in presets" @click="setTime(preset.value, '00')" :key="preset.value"
          :class="{ 'active': isSelected(preset.value) }" :disabled="!isValidTime(preset.value)" class="modal__preset">
          {{ preset.title }}
        </a-button>
      </div>

      <div v-if="mode === 'custom'" class="modal__smooth">
        <SmoothTimeSelect :date="currentDate" @update="setSmoothTime" :parent-open="isOpen" />
      </div>

      <div class='modal__block-footer'>
        <a-button v-show="mode === 'presets'" @click="setMode('custom')" class="modal__block-inline-button">Custom
          time</a-button>
        <a-button v-show="mode === 'custom'" @click="mode = 'presets'"
          class="modal__block-inline-button">Presets</a-button>
        <a-button :disabled="!isValidTime(selectedTime)" @click="$emit('close', selectedTime)"
          class="modal__block-button">Apply</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import Icon from '@/components/Icon'
import SmoothTimeSelect from '@/components/SmoothSelect/SmoothTimeSelect'

export default {
  components: {
    Icon, SmoothTimeSelect
  },
  props: {
    minValidDate: {
      type: Object,
      required: true
    },
    maxValidDate: {
      type: Object,
      required: true
    },
    date: {
      type: Object
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      mode: 'presets', // presets / custom
      currentDate: moment(this.date),
      selectedTime: this.defaultValue(),
      customUpdated: false,
      presets: [
        { value: 10, title: '10 AM' },
        { value: 11, title: '11 AM' },
        { value: 12, title: '12 AM' },
        { value: 13, title: '1 PM' },
        { value: 14, title: '2 PM' },
        { value: 15, title: '3 PM' },
        { value: 16, title: '4 PM' },
        { value: 17, title: '5 PM' },
        { value: 18, title: '6 PM' },
        { value: 19, title: '7 PM' },
        { value: 20, title: '8 PM' },
        { value: 21, title: '9 PM' },
      ]
    }
  },
  watch: {
    date(value) {
      this.currentDate = value;
    },
    currentDate() {
      this.selectedTime = this.currentDate
    }
  },
  computed: {
    currentTime() {
      return moment(this.selectedTime).format("HH:mm");
    },

  },
  methods: {
    setMode(mode) {
      if (mode === 'custom') {
        this.customUpdated = false
      }
      this.mode = mode;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    setTime(hours, minutes) {
      this.currentDate = moment(this.currentDate)
        .hour(hours)
        .minute(minutes);
    },
    setSmoothTime(date) {
      this.customUpdated = true
      this.currentDate = moment(date)
    },
    closeModal() {
      this.$emit('close', null)
    },
    isValidTime(time) {
      if (this.mode === 'custom' && !this.customUpdated) {
        return false
      } else if (this.mode === 'custom' && this.customUpdated) {
        return true
      }
      this.acceptDisable = false
      let selectedDateWithTime = moment(this.date).hours(time)
      if (moment(selectedDateWithTime).isBefore(this.minValidDate) || moment(selectedDateWithTime).diff(moment(this.minValidDate)) === 0 || moment(selectedDateWithTime).isAfter(this.maxValidDate) || moment(selectedDateWithTime).diff(this.maxValidDate) === 0)
        return false
      return true
    },
    defaultValue() {
      if (moment(this.date).format("mm") !== '00' && moment(this.date).hours() < 21 && moment(this.date).hours() === moment(this.minValidDate).hours()) {
        return moment(this.date).minutes(0).add(1, 'h')
      } else {
        return moment(this.date)
      }
    },
    isSelected(hours) {
      return moment(this.selectedTime).hours() === hours
    }
  },
}
</script>

<style lang="stylus" scoped>
  .modal {
    &__wrapper {
      position fixed
      inset 0
      background-color: rgba(0,0,0, 0.6);
      z-index 2
      padding 0 16px
    }

    &__time-value {
      background: #262B3F;
      border-radius: 8px;
      margin 154px 0 16px 206px
      padding 16px
      height 64px
      display flex
      justify-content center
      align-items center
      gap 27px
    }

    &__time-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #EEEEEE;
    }

    &__time-icon {
      color: #D8D8D8;
      width 24px
      min-width: 24px;
    }

    &__block {
      background: #262B3F;
      border-radius: 12px;
      padding 16px
    }

    &__block-footer {
      display flex
      justify-content space-between
      align-items center
      gap 20px
    }

    &__block-button {
      height 40px
      padding 8px 24px
      flex: 0;
      margin 0
    }

    &__block-inline-button {
      height 40px
      padding 8px 0
      background-color: transparent
      color #6293FF;
      border: 0
      flex: 0;
      margin 0
    }

    &__presets {
      display flex
      flex-wrap wrap;
      gap 12px 16px
      margin-bottom 36px
    }

    &__preset {
      display flex
      justify-content center
      align-items center
      min-width 86px
      margin: 0
      padding 10px 16px
      flex 1

      background: #383D54;
      border-radius: 8px;
      color: #EEEEEE;
      border: 0

      &.active {
        background-color: #6293FF;
      }

      &:disabled,
      &.disabled {
        color: #7B8B97

        &:hover {
          background-color: #383d54;
        }
      }


    }

    &__smooth {
      margin-bottom 20px
    }
  }

</style>